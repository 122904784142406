<template lang="pug">
  div.page-offer-form

    loading(:active.sync="busy", :is-full-page="true")

    form(@submit.prevent="submit", v-if="offer")

      .position-relative
        .buttons.pos-right
          router-link.btn.btn-default(:to="{name:'offer-list'}")
            i.la.la-angle-left
            | Back
          button.btn.btn-primary.ml-2(type="submit", :disabled="!!offer.reserved") Save
            i.la.la-fw.la-save

        b-tabs
          b-tab(title="Main")
            .row
              .col-md-6
                .widget
                  .widget-header
                    h1.title {{ offer.id ? 'Edit offer' : 'Create offer' }}: [{{advertiser.id}}] {{advertiser.name}}
                    div.info-buttons
                      router-link(:to="{name:'placement-list', query:{offer_id:offer.id}}")
                        a.btn.btn-circle.btn-primary(v-b-tooltip.hover.bottom, title="Placements")
                          i.la.la-list
                      | &nbsp;
                      router-link(:to="{name:'reports', query:{offer_id:offer.id}}")
                        a.btn.btn-circle.btn-primary(v-b-tooltip.hover.bottom, title="Statistics")
                          i.la.la-bar-chart
                  .widget-body
                    div.info-pane(v-if="offer.id")
                      div.info-row
                        span.info-label Advertiser
                        span.info-value [<span class="hl">{{ advertiser.id }}</span>] {{ advertiser.name }} &nbsp;
                          router-link(:to="{name:'advertiser-edit', params: {id: advertiser.id}}", target="_blank")
                            i.la.la-external-link

                    .row
                      .col-sm-4
                        .form-group
                          label ID
                          input.form-control(type="text", placeholder="", readonly="readonly", v-model="offer.id || ''")
                      .col-sm-4
                        .form-group
                          label Status
                          select.form-control(v-model="offer.status", name="status")
                            option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
                      .col-sm-4
                        .form-group
                          label Account Manager&nbsp;
                            i.la.la-info-circle(v-b-tooltip.hover.right, title="Assign yourself to receive notifications regarding this offer. See more in Profile Settings")
                          select.form-control(v-model="offer.user_id", name="user")
                            option(:value="null") None
                            option(v-for="o in userOptions", :value="o.v") {{ o.t }}
                      .col-sm-6
                        .form-group(v-if="offer.externalOffer")
                          label Adv. ID
                          input.form-control(type="text", placeholder="", readonly="readonly", v-model="offer.externalOffer.external_id")
                      .col-sm-6
                        .form-group(v-if="offer.externalOffer")
                          label Adv. CampaignID
                          input.form-control(type="text", placeholder="", readonly="readonly", v-model="offer.externalOffer.external_campaign_id")

                    .row
                      .col-sm-12
                        .form-group
                          label Offer Name
                          input.form-control(type="text", placeholder="Name", v-model="offer.name")

                      .col-sm-12(v-if="offer.parentOffers && offer.parentOffers.length")
                        .form-group.complex-offers-container
                          label Complex Offer
                          .complex-offer-container(v-for="po in offer.parentOffers")
                            span [{{ po.id }}] {{ po.name }}
                            .main-switcher-container
                              i.la.la-link.clickable.main-switcher(v-b-tooltip.hover, title="Super-linked", v-if="offer.main_parent_offer_id === po.id", @click="() => updateMainParentOffer(po.id)")
                              i.la.la-unlink.clickable.main-switcher(v-b-tooltip.hover, title="Unlinked", v-if="offer.main_parent_offer_id !== po.id", @click="() => updateMainParentOffer(po.id)")

                            router-link.complex-offer-link(:to="{name:'offer-edit', params: {id: po.id}}", target="_blank", style="color: white")
                              i.la.la-external-link

                      .col-sm-6
                        .form-group
                          label Start Date
                          b-form-datepicker(v-model="offer.date_start", placeholder="No limit", :state="validDateRange", @input="onDateChange", reset-button)
                      .col-sm-6
                        .form-group
                          label End Date
                          b-form-datepicker(v-model="offer.date_end", placeholder="No limit", :state="validDateRange", @input="onDateChange", reset-button)

                    //.form-group
                      label Type
                      select.form-control(v-model="offer.type")
                        option(value="normal") Normal
                        option(value="complex") Complex


                .widget
                  .widget-header
                    h2.title Campaign Information
                  .widget-body
                    .form-group
                      label Short Description
                      textarea.form-control(placeholder="Short description", v-model="offer.short_description", rows="5")
                    .form-group
                      label Full Description
                      textarea.form-control(placeholder="Full description", v-model="offer.description", rows="5")
                    .form-group
                      label User Flow
                      textarea.form-control(placeholder="User Flow", v-model="offer.user_flow", rows="5")
                    .form-group
                      label Restrictions / KPI Description
                      textarea.form-control(placeholder="Explain the KPI and restrictions", v-model="offer.kpi")
                    .row
                      .col-sm-6
                        h4.widget-section-title Restriction List
                        .form-group
                          div(v-for="r in restrictionList")
                            b-form-checkbox(v-model="offer.restriction_hash[r.code]") {{ r.label }}
                      .col-sm-6
                        h4.widget-section-title Additional Restriction Parameters
                        .form-group
                          b-form-checkbox(v-model="offer.allow_incent") Allow Incent
                        .form-group
                          b-form-checkbox(v-model="offer.hard_kpi") Hard KPI


              .col-md-6
                .widget
                  .widget-header
                    h2.title Store Information
                  .widget-body
                    div(style="display: flex;")
                      div(style="flex: 1;")
                        .form-group
                          label Preview URL
                          .input-group
                            input.form-control(type="text", placeholder="Preview URL", v-model="offer.preview_url")
                            .input-group-append
                              button.btn.btn-secondary(type="button", @click="fetchStoreData()")
                                i.la.la-link
                        .form-group
                          label Store Bundle ID
                          input.form-control(type="text", placeholder="com.appname.example", v-model="offer.store_bundle_id")

                        .form-group
                          label Package ID (iOS)
                          input.form-control(type="text", placeholder="12345678", v-model="offer.store_package_id")


                      div(style="padding: 16px 0 0 16px;")
                        div.app-icon-container
                          img(v-if="offer.store_icon", :src="offer.store_icon")

                .widget
                  .widget-header
                    h2.title Tracking
                  .widget-body
                    .form-group
                      b-form-checkbox(v-model="offer.lock_tracking_url", v-if="isAutomaticOffer") Lock tracking links
                      b-form-checkbox(v-model="offer.is_direct_link") Is Direct Link
                      b-form-checkbox(v-model="offer.require_validation") Require link validation (affilitest)
                      //- b-form-checkbox(v-if="USER.config && USER.config.s2sOffer" v-model="offer.s2s") S2S Support (Server-To-Server)
                    .form-group
                      .d-flex.justify-content-between
                        label Click URL
                        // a(@click="fetchClickLink()")
                          i.la.la-link
                      url-input(v-model="offer.tracking_url", :macros="urlMacros", label="Click URL", :locked="offer.lock_tracking_url", v-on:mmp="updateOfferMMP", :offer="offer", v-on:customParams="updateOfferCustomParams")
                    .form-group(v-if="offer.mmp === 'appsflyer' && selectedMmpAccount && selectedMmpAccount.af_clicks_signing_mode !== 'disabled'")
                      label Click Expiration Hours
                      input.form-control(type="number", v-model="offer.af_click_expiration_hours")
                    .form-group(v-if="offer.mmp === 'appsflyer' && selectedMmpAccount && selectedMmpAccount.af_clicks_signing_mode !== 'disabled'")
                      button.btn.btn-primary.mr-2(@click="testAfClickSigning", type="button") Test Click Signing
                      span.text-muted(v-if="clickSigningResult") {{ clickSigningResult }}
                    .form-group
                      .d-flex.justify-content-between
                        label Impression URL
                        // a(@click="fetchImpressionLink()")
                          i.la.la-link
                      url-input(v-model="offer.impression_url", :macros="urlMacros", label="Impression URL", :locked="offer.lock_tracking_url", v-on:mmp="updateOfferMMP", :offer="offer", v-on:customParams="updateOfferCustomParams")

                    .form-group(v-if="mmpAccountOptions.length")
                      label MMP Account
                      select.form-control(v-model="offer.mmp_account_id")
                        option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}

                    //.form-group
                      label Custom Fallback URL
                      input.form-control(type="text", v-model="offer.fallback_url")


                .widget
                  .widget-header
                    h2.title Comments (Internal use)
                  .widget-body
                    .form-group
                      //label Comments (Internal use)
                      textarea.form-control(rows="6", placeholder="Comments (Internal use)", v-model="offer.comments")

          b-tab(title="Targeting")
            .row
              .col-sm-6
                .widget
                  .widget-header
                    h2.title General Settings
                  .widget-body
                    //.form-group
                      label _Country
                      textarea.form-control(rows="3", placeholder="GB, US, ...", v-model="offer.country")
                    .form-group
                      label Country &nbsp;
                        i.la.la-lock(v-if="offer.lock_country", v-b-tooltip.hover.right, title="Auto-update disabled")
                      b-input-group
                        b-form-textarea(rows="3", placeholder="GB, US, ...", v-model="offer.country")
                        b-input-group-append
                          b-button(:variant="offer.lock_country ? 'outline-danger' : 'outline-success'", @click="offer.lock_country = !offer.lock_country")
                            i.la(:class="{'la-lock': offer.lock_country, 'la-lock-open': !offer.lock_country}")
                    .form-group
                      label Platform
                      select.form-control(v-model="offer.platform")
                        option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
                      //input.form-control(type="text", placeholder="ios, android, all", v-model="offer.platform")

                    //.row
                      .col-sm-6
                        .form-group
                          b-form-checkbox(v-model="offer.restrict_country") Restrict country
                      .col-sm-6
                        .form-group
                          b-form-checkbox(v-model="offer.restrict_bots") Restrict bots (Amazon)
                .widget
                  .widget-header
                    h2.title Timeframe restriction
                  .widget-body
                    timeframe-restriction(:params="timeframeParams", v-on:update-timeframe="updateTimeframe")

                os-network-targeting(v-model="osNetworkParams")

              .col-sm-6
                geo-targeting(:params="geoParams", v-on:update-geo="updateGeo", :isAutomaticOffer="isAutomaticOffer")
                device-type-targeting(v-model="offer.device_type_blacklist")


          b-tab(title="Data Control", v-if="USER.permissions['offers::DATA_CONTROL'] || USER.permissions['offers::DATA_CONTROL_ADVANCED']")
            .row
              .col-sm-6
                .widget(v-if="USER.permissions['offers::DATA_CONTROL_ADVANCED']")
                  .widget-header
                    h2.title Data Control
                  .widget-body
                    .row
                      .col-sm-6
                        .form-group
                          label AppName Mode
                          select.form-control(v-model="offer.app_name_mode")
                            option(v-for="o in appnameModeOptions", :value="o.v") {{ o.t }}
                          //b-form-checkbox(v-model="offer.enable_override_app_names") Enable custom app names
                        .form-group
                          textarea.form-control(rows="5", placeholder="", v-model="offer.override_app_names",
                            :disabled="offer.app_name_mode == 'none' || offer.app_name_mode == 'block_empty'")
                      .col-sm-6
                        .form-group
                          label BundleID Mode
                          select.form-control(v-model="offer.bundle_id_mode")
                            option(v-for="o in customModeOptions", :value="o.v") {{ o.t }}
                          //b-form-checkbox(v-model="offer.enable_override_bundle_ids") Enable custom bundle IDs
                        .form-group
                          textarea.form-control(rows="5", placeholder="", v-model="offer.override_bundle_ids",
                            :disabled="offer.bundle_id_mode == 'none' || offer.bundle_id_mode == 'block_empty'")
                    .row
                      .col-sm-6
                        .form-group
                          label Subsource Mode
                          select.form-control(v-model="offer.subsource_mode")
                            option(v-for="o in subsourceModeOptions", :value="o.v") {{ o.t }}
                          //b-form-checkbox(v-model="offer.enable_override_sources") Enable custom sources
                        .form-group(style="position:relative")
                          textarea.form-control(rows="5", placeholder="", v-model="offer.override_sources",
                            :disabled="offer.subsource_mode === 'none' || offer.subsource_mode === 'block_empty' || offer.subsource_mode === 'encode' || offer.subsource_auto_generate")

                        div(v-if="offer.subsource_mode === 'override' || offer.subsource_mode === 'override_empty'")
                          .row
                            .col-sm-12(style="position:relative")
                              b-form-checkbox(v-model="offer.subsource_auto_generate") Refresh Subsources &nbsp;
                                small.text-muted(v-if="offer.subsource_auto_generate && offer.subsource_auto_frequency && offer.subsource_auto_total > 0 && offer.subsource_last_override", v-b-tooltip.hover.right, title="Last refresh date") {{ offer.subsource_last_override }}
                              p.form-text(v-if="offer.subsource_auto_generate && offer.subsource_auto_frequency && offer.subsource_auto_total > 0")
                                i.la.la-check
                                | &nbsp; Auto generate {{offer.subsource_auto_total }} subsources every {{ offer.subsource_auto_frequency }} hours
                              button.btn.sm-btn(v-if="offer.subsource_auto_generate", type="button", @click="editAutoGenerate = !editAutoGenerate", style="position:absolute; right: 13px; top:0; z-index:1")
                                i.la(:class="{'la-pencil': !editAutoGenerate, 'la-times': editAutoGenerate}")
                          .row(v-if="editAutoGenerate || (offer.subsource_auto_generate && (!offer.subsource_auto_frequency || offer.subsource_auto_total=='0'))")
                            .col-sm-6
                              .form-group
                                label Total
                                input.form-control(type="text", v-model="offer.subsource_auto_total")
                            .col-sm-6
                              .form-group
                                label Freq
                                select.form-control(v-model="offer.subsource_auto_frequency")
                                  option(:value="0") None
                                  option(:value="6") 6 hrs
                                  option(:value="12") 12 hrs
                                  option(:value="24") 24 hrs
                                  option(:value="48") 48 hrs
                                  option(:value="72") 72 hrs
                                  option(:value="168") Week

                          .row
                            .col-sm-12
                              b-form-checkbox(v-if="offer.mmp==='appsflyer'", v-model="offer.subsource_auto_optimize") Optimize Subsources
                              p.form-text(v-if="offer.subsource_auto_optimize")
                                i.la.la-check
                                | &nbsp; Replace sources based on performance

                      .col-sm-6
                        .form-group
                          label DeviceID Mode (IDFA/GAID)
                          select.form-control(v-model="offer.deviceid_mode")
                            option(v-for="o in deviceidModeOptions", :value="o.v") {{ o.t }}
                        //.form-group
                        //label Use Sources
                        textarea.form-control(rows="5", placeholder="Suppression List", v-model="offer.deviceid_suppression")
                        .form-group(v-if="USER.config && USER.config.deviceBL")
                          b-form-checkbox(v-model="offer.use_device_blacklisted") Use Device Blacklist
                            i.la.la-info-circle.ml-1(v-b-tooltip.hover.bottom, title="Block every device that is on the current restricted list and clicks with no device id")
                    .row
                      .col-sm-6
                        .form-group
                          label Referer Mode
                          select.form-control(v-model="offer.referer_mode")
                            option(v-for="o in refererModeOptions", :value="o.v") {{ o.t }}
                      .col-sm-6
                        .form-group
                          label Distribution Format
                          select.form-control(v-model="offer.distribution_format")
                            option(v-for="o in distributionModeOptions", :value="o.v") {{ o.t }}


                    //p.clearfix &nbsp;
                    h4.widget-section-title(v-if="USER.config && USER.config.impressionRate") Misc
                    .row(v-if="USER.config && USER.config.impressionRate")
                      .col-sm-12
                        impression-ratio(v-model="offer.impression_rate")

                    h3.widget-section-title Additional Filters
                    .row
                      .col-sm-6
                        .form-group
                          b-form-checkbox(v-model="offer.block_proxies") Block Proxies
                        .form-group
                          b-form-checkbox(v-model="offer.block_iframe") Block Iframes
                      .col-sm-6
                        .form-group
                          b-form-checkbox(v-model="offer.block_pixels") Block Pixels
                        .form-group
                          b-form-checkbox(v-model="offer.normalize_bundleid") Normalize BundleID (remove "id")


              .col-sm-6

                // .widget
                  .widget-header
                    h2.title Subsource Encoding
                  .widget-body
                    div.alert.alert-warning <strong>Subsource Mode</strong> must be set to &quot;Encode&quot; to enable encoding
                    .row.block-row(:class="{'block-row-inactive':!offer.sub1_encode_prefix || offer.subsource_mode !== 'encode'}")
                      .col-sm-6
                        .form-group
                          label &nbsp;
                          .form-text
                            b-form-checkbox(v-model="offer.sub1_encode_prefix") Encode publisher ID
                      .col-sm-6
                        .form-group
                          label Frequency
                          select.form-control(v-model="offer.sub1_encode_prefix_freq")
                            option(v-for="o in encodeFreqOptions" :value="o.v") {{ o.t }}

                    .row.block-row(:class="{'block-row-inactive':!offer.sub1_encode_id || offer.subsource_mode !== 'encode'}")
                      .col-sm-6
                        .form-group
                          label &nbsp;
                          .form-text
                            b-form-checkbox(v-model="offer.sub1_encode_id") Encode SubID (masked only)
                      .col-sm-6
                        .form-group
                          label Frequency
                          select.form-control(v-model="offer.sub1_encode_id_freq")
                            option(v-for="o in encodeFreqOptions" :value="o.v") {{ o.t }}

                    p.clearfix &nbsp;
                    .row
                      .col-sm-6
                        .form-group
                          label Sub1 merge limit (masked only)
                          input.form-control(type="text", v-model="offer.sub1_modulus")
                          p.form-text Merge several sources into a single AdvSource

                .widget
                  .widget-header
                    h2.title Caps
                  .widget-body
                    h3.widget-section-title Click & Conversion
                    .form-group
                      label Offer timezone
                      select.form-control(v-model="offer.timezone")
                        option(v-for="o in timezones", :value="o.v") {{ o.t }}
                    .form-group
                      b-form-checkbox(v-model="offer.lock_conversion_caps", v-if="isAutomaticOffer") Lock conversion caps
                      b-form-checkbox(v-model="offer.lock_caps", v-if="isAutomaticOffer") Lock Click/Impression caps
                      b-form-checkbox(v-model="offer.hide_caps") Hide caps
                    .row
                      .col-sm-6
                        .form-group
                          label Daily Click Cap
                            i.la.la-lock.ml-1(v-if="offer.lock_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          number-input(v-model="offer.daily_click_cap")
                          p.form-text Advertiser: {{ advertiser.default_offer_cap }}
                      .col-sm-6
                        .form-group
                          label Daily Impression Cap
                            i.la.la-lock.ml-1(v-if="offer.lock_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          number-input(v-model="offer.daily_impression_cap")
                          p.form-text(v-if="offer.daily_click_cap > 0 && offer.daily_impression_cap === 0") Paused on click cap &nbsp;
                            i.la.la-info-circle(v-b-tooltip.hover.bottom, title="There is no cap on impressions, but once the click cap is reached offer will be paused")
                    .row
                      .col-sm-6
                        .form-group
                          label Daily Conversion Cap
                            i.la.la-lock.ml-1(v-if="offer.lock_conversion_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          number-input(v-model="offer.daily_conversion_cap")
                      .col-sm-6
                        .form-group
                          label Weekly Conversion Cap
                            i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Monday to Sunday")
                            i.la.la-lock.ml-1(v-if="offer.lock_conversion_caps", v-b-tooltip.hover.right, title="Auto-update disabled")
                            i.la.la-eye-slash.ml-1(v-if="offer.hide_caps", v-b-tooltip.hover.right, title="Not visible in API")
                          number-input(v-model="offer.weekly_conversion_cap")
                      //.col-sm-4
                        .form-group
                          label Restrict C2C
                          input.form-control(type="text", placeholder="0", v-model="offer.restrict_clicks_convert")
                    .row(v-if="offer.payout_type === 'CPA'")
                      .col-sm-6
                        .form-group
                          label Daily Install Cap
                            i.la.la-eye-slash.ml-1(v-b-tooltip.hover.right, title="Not visible in API")
                          number-input(v-model="offer.daily_install_cap")

                    h3.widget-section-title Budget
                    .row
                      .col-sm-6
                        .form-group
                          label Monthly Budget
                          number-input(v-model="offer.monthly_budget", prefix="$ ", fraction="2")
                        .form-group(v-if="offer.id && (offer.daily_budget || offer.monthly_budget)")
                          label Used
                          b-progress(:value="offer.monthly_budget > 0 ? offer.used_monthly_budget : 0", :max="offer.monthly_budget", show-progress, animated, height="2rem")

                      .col-sm-6
                        .form-group
                          label Daily Budget
                          number-input(v-model="offer.daily_budget", prefix="$ ", fraction="2")
                        .form-group(v-if="offer.id && (offer.daily_budget || offer.monthly_budget)")
                          label Used
                          b-progress(:value="offer.daily_budget > 0 ? offer.used_daily_budget : 0", :max="offer.daily_budget", show-progress, animated, height="2rem")

                    h3.widget-section-title Click Pacing
                    .row
                      .col-sm-6
                        .form-group
                          label Minute
                          number-input(v-model="offer.click_pacing")
                          p.form-text.text-muted(v-if="!offer.click_pacing") No limit of clicks / minute
                          p.form-text(v-if="offer.click_pacing") Max {{offer.click_pacing | numberCommas }} clicks / minute
                      .col-sm-6
                        .form-group
                          label Hour
                          number-input(v-model="offer.click_pacing_hour")
                          p.form-text.text-muted(v-if="!offer.click_pacing_hour") No limit of clicks / hour
                          p.form-text(v-if="offer.click_pacing_hour") Max {{offer.click_pacing_hour | numberCommas }} clicks / hour
                    h3.widget-section-title Impression Pacing
                    .row
                      .col-sm-6
                        .form-group
                          label Minute
                          number-input(v-model="offer.impression_pacing")
                          p.form-text.text-muted(v-if="!offer.impression_pacing") No limit of impressions / minute
                          p.form-text(v-if="offer.impression_pacing") Max {{offer.impression_pacing | numberCommas }} impressions / minute
                      .col-sm-6
                        .form-group
                          label Hour
                          number-input(v-model="offer.impression_pacing_hour")
                          p.form-text.text-muted(v-if="!offer.impression_pacing_hour") No limit of impressions / hour
                          p.form-text(v-if="offer.impression_pacing_hour") Max {{offer.impression_pacing_hour | numberCommas }} impressions / hour


                    // h3.widget-section-title.mt-2(v-if="USER.permissions['offers::SUBSOURCE_LIMIT']") Subsource Limit
                    // .row(v-if="USER.permissions['offers::SUBSOURCE_LIMIT']")
                      .col-sm-6
                        .form-group
                          label Daily Subsource Limit
                          .input-group
                            select.form-control(v-model="dailySubsourceLimitMode")
                              option(value="advertiser") Use Advertiser Default
                              option(value="nolimit") No Limit
                              option(value="custom") Custom
                            input.form-control(type="text", v-model="offer.daily_subsource_limit", v-if="dailySubsourceLimitMode=='custom'")
                          p.form-text Number of <strong>daily</strong> unique subsources (Advertiser: {{ advertiser.daily_subsource_limit }}).
                      .col-sm-6
                        .form-group
                          label Total Subsource Limit
                          .input-group
                            select.form-control(v-model="totalSubsourceLimitMode")
                              option(value="advertiser") Use Advertiser Default
                              option(value="nolimit") No Limit
                              option(value="custom") Custom
                            input.form-control(type="text", v-model="offer.total_subsource_limit", v-if="totalSubsourceLimitMode=='custom'")
                          p.help-block Number of <strong>total</strong> unique subsources (Advertiser: {{ advertiser.total_subsource_limit }}).


                    h3.widget-section-title Frequency Cap
                    frequency-cap(v-model="offer.frequency_cap_params")

                .widget
                  .widget-header
                    h2.title Whitelist/Blacklist Parameters
                  .widget-body
                    b-tabs.wl-bl-tabs
                      b-tab(v-for="f in blwlFields", v-bind:key="f.v")
                        template(#title)
                          span(:class="'icon-'+offer[f.v+'_blwl_mode']")
                            i.la.la-dot-circle &nbsp;
                          span {{ f.t }}
                        div(style="padding: 15px;")
                          div.form-inline
                            .form-group(style="line-height:21px;")
                              label(style="padding-right: 20px;") Mode:
                              b-form-radio-group(v-model="offer[f.v+'_blwl_mode']", :name="f.v+'_blwl_mode'")
                                b-form-radio(:value="null") None
                                b-form-radio(value="blacklist") Blacklist
                                b-form-radio(value="whitelist") Whitelist
                          br
                          .row
                            .col-sm-6
                              .form-group
                                label Blacklist
                                textarea.form-control(rows="5", placeholder="", v-model="offer[f.v+'_blacklist']", :disabled="offer[f.v+'_blwl_mode']!=='blacklist'")
                            .col-sm-6
                              .form-group
                                label Whitelist
                                textarea.form-control(rows="5", placeholder="", v-model="offer[f.v+'_whitelist']", :disabled="offer[f.v+'_blwl_mode']!=='whitelist'")
                    .col-sm-12
                      .form-group
                        label IP Blacklist
                          small.ml-1 (line separated)
                          i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="These IP will be blocked only on this offer")
                        textarea.form-control(rows="5", placeholder="", v-model="offer.ip_black_list")


                //.widget(v-if="USER.permissions['offers::DATA_CONTROL_ADVANCED']")
                  .widget-header
                    h2.title Translate Parameters
                  .widget-body
                    table.table.table-bordered.translate-params-list
                      thead
                        tr
                          th Param
                            button.btn-rounded.btn-primary(type="button", @click="addTranslateParam()")
                              i.la.la-plus
                          th From
                          th To
                          th &nbsp;
                      tbody(v-for="(p, i) in offer.translate_params")
                        tr.row-param
                          td(colspan="3")
                            input.form-control(type="text", v-model="p.param")
                            button.btn-rounded.btn-secondary(type="button", @click="addTranslateParamItem(p)")
                              i.la.la-plus
                          td
                            button.btn-rounded.btn-secondary(type="button", @click="offer.translate_params.splice(i,1)")
                              i.la.la-minus
                        tr(v-for="(r,j) in p.values").row-param-value
                          td &nbsp;
                          td
                            input.form-control(type="text", v-model="r.k")
                          td
                            input.form-control(type="text", v-model="r.v")
                          td
                            button.btn-rounded.btn-secondary(type="button", @click="p.values.splice(j,1)")
                              i.la.la-minus

                //.widget(v-if="USER.permissions['offers::DATA_CONTROL_ADVANCED']")
                  .widget-header
                    h2.title Parameter Route Mapping
                  .widget-body
                    p.
                      This feature will route incoming traffic to other offers/placements based
                      on query parameters. This feature precedes other rules/connected offers.
                    table.table.table-bordered.translate-params-list
                      thead
                        tr
                          th Param
                            button.btn-rounded.btn-primary(type="button", @click="addRouteParam()")
                              i.la.la-plus
                          th From
                          th Type
                          th ID
                          th &nbsp;
                      tbody(v-for="(p, i) in offer.route_params")
                        tr.row-param
                          td(colspan="4")
                            input.form-control(type="text", v-model="p.param")
                            button.btn-rounded.btn-secondary(type="button", @click="addRouteParamItem(p)")
                              i.la.la-plus
                          td
                            button.btn-rounded.btn-secondary(type="button", @click="offer.route_params.splice(i,1)")
                              i.la.la-minus
                        tr(v-for="(r,j) in p.values").row-param-value
                          td &nbsp;
                          td
                            input.form-control(type="text", v-model="r.k")
                          td
                            select.form-control(v-model="r.t")
                              option(value="placement") Placement
                              option(value="offer") Offer
                          td
                            input.form-control(type="text", v-model="r.id")
                          td
                            button.btn-rounded.btn-secondary(type="button", @click="p.values.splice(j,1)")
                              i.la.la-minus


          b-tab(title="Connected Publishers")
            .widget
              .widget-header
                h2.title Connect to publishers
                b-form-checkbox(v-model="showActivePubsOnly") Show active only
              .widget-body
                .row
                  // :disabled="p.id !== null"
                  .col-sm-4(v-for="p in placementStatusList.filter(p => !showActivePubsOnly || p.publisher_status)")
                    .form-text
                      b-form-checkbox(:class="'cstatus-'+p.status", v-model="p.create_placement", v-if="!showActivePubsOnly || p.publisher_status", :disabled="p.disabled")
                        i.la.la-robot.mr-1(v-if="p.disabled", v-b-tooltip.hover.right, title="Updating via Advertiser's Auto-Connect settings")
                        span {{ p.publisher_name }}



          b-tab(title="Payout & Events")

            .row
              .col-md-6
                .widget.widget
                  .widget-header
                    h2.title Payout
                  .widget-body
                    .row
                      .col-sm-4
                        .form-group
                          label Payout Model
                          select.form-control(v-model="offer.payout_type", name="payout_type")
                            option(v-for="o in payoutTypeOptions", :value="o.v") {{ o.v }}
                      .col-sm-4
                        .form-group
                          label Payout
                          input.form-control(type="text", placeholder="Payout", v-model="offer.payout")
                          b-form-checkbox(v-model="offer.auto_payout") Automatically update payout (API)
                      .col-sm-4
                        .form-group
                          label Currency
                          select.form-control(v-model="offer.currency")
                            option(v-for="o in currencyOptions", :value="o.v", :disabled="o.disabled") {{ o.t }}
              .col-md-6
                .widget.widget
                  .widget-header
                    h2.title Settings
                  .widget-body
                    //.form-group
                      b-form-checkbox(v-model="offer.event_managed") Is conversion managed by events?
                    .form-group
                      b-form-checkbox(v-model="offer.event_convert_payout_only") Auto-Convert if has payout
                    //.form-group
                      b-form-checkbox(v-model="offer.auto_events") Auto build events from postbacks
                    //.form-group
                      b-form-checkbox(v-model="offer.report_retention") Auto report related conversions when receiving events (optimize for retention)?
                    .form-group
                      b-form-checkbox(v-model="offer.allow_multiple_conversions", :disabled="offer.payout_type==='CPI'") Allow multiple conversions for the same click
                    .form-group
                      b-form-checkbox(v-model="offer.lock_events", :disabled="shouldLockEvents", v-if="isAutomaticOffer") Lock events change
            .widget
              .widget-header(style="justify-content: initial")
                span
                  h2.title Events
                span.ml-2(v-if="shouldLockEvents")
                  i.la.la-link(v-b-tooltip.hover, :title="`Events managed by a complex offer - ID ${mainParentOffer.id}, ${mainParentOffer.name}`", style="font-size:1.8rem")
              .widget-body
                table.event-list.table
                  thead
                    tr
                      th(v-if="!shouldLockEvents") &nbsp;
                      th &nbsp;
                      th.col-event-name Event Name
                        i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="Must match the event_name as it reported over the postbacks that are sent by the advertiser/MMP")
                      th.col-event-label Event Label
                      th.col-event-payout Payout
                      th.col-event-payout Publisher Event
                        i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="(Optional) Use this to fire a different event name over the publisher postbacks")
                      th.text-center Report
                      th.text-center Once
                      th(v-if="!shouldLockEvents") &nbsp;
                  tbody
                    tr.row-none
                      td
                        div.drag-handle &nbsp;
                      td &nbsp;
                      td.event-list-item
                        span None
                      td.event-list-item
                        span &nbsp;
                      td.event-list-item
                        span 0
                      td &nbsp;
                      td &nbsp;
                      td &nbsp;
                      //td.text-center
                        input(type="radio", v-model="offer.event_idx_conversion_postback", :value="0")
                      //td.text-center
                        input(type="radio", v-model="offer.event_idx_conversion", :value="0")
                      td &nbsp;

                    tr
                      td(v-if="!shouldLockEvents") &nbsp;
                      td.text-center ins.
                      td
                        span.form-control {{ advertiser.custom_install_event_name }}
                      td
                        span.form-control Install
                      td &nbsp;
                      td
                        span.form-control install
                      td.text-center &nbsp;
                        // input(type="checkbox", v-model="e.report")
                      td.text-center &nbsp;
                        input(type="checkbox", checked="checked", disabled="disabled")
                      td(v-if="!shouldLockEvents") &nbsp;

                  draggable(:disabled="shouldLockEvents", tag="tbody", v-model="offer.events", handle=".drag-handle", ghost-class="ghost", :group="{name: 'events', pull: 'clone', put: false}")
                    tr(v-for="(e, i) in offer.events")
                      td(v-if="!shouldLockEvents")
                        div.drag-handle
                          svg(width="24px", fill="currentColor", viewBox="0 0 24 24")
                            path(d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z")
                            path(d="M0 0h24v24H0z", fill="none")
                      td.text-center e{{i+1}}
                      td.event-list-item
                        input.form-control(type="text", placeholder="Name", v-model="e.name", :class="{'is-invalid':e.name && e.name.toLowerCase() === 'install'}")
                        .invalid-feedback(v-if="e.name && e.name.toLowerCase() === 'install'") This name is reserved
                      td.event-list-item
                        input.form-control(type="text",  :disabled="shouldLockEvents", placeholder="Label", v-model="e.label")
                      td.event-list-item.multi-inputs
                        select.form-control(v-model="e.payout_mode")
                          option(value="none") None
                          option(value="fixed") Fixed
                          option(value="dynamic") Dynamic
                        .input-group-wrapper
                          .input-group(v-if="e.payout_mode !== 'none'")
                            input.form-control(type="text", placeholder="Payout", v-model="e.payout")
                            .input-group-append
                              span.input-group-text(v-if="e.payout_mode === 'dynamic'") %
                              span.input-group-text(v-if="e.payout_mode === 'fixed'") $
                      td.event-list-item
                        input.form-control(type="text", :disabled="shouldLockEvents", placeholder="Event name", v-model="e.pub_event_name")
                      td.text-center
                        input(type="checkbox", v-model="e.report")
                      td.text-center
                        input(type="checkbox", v-model="e.unique")
                      td.actions(v-if="!shouldLockEvents")
                        a.btn.btn-secondary(@click="offer.events.splice(i, 1)", href="javascript:void(0)")
                          i.la.la-trash
                p
                  button.btn.btn-secondary(v-if="!shouldLockEvents", type="button", @click="addEvent()") Add

          b-tab(title="KPI")
            kpi-rules(v-model="offer.rules", :events="offer.events", :generalKpiRules="generalKpiRules",
              :generalCustomKpiRules="generalCustomKpiRules", :offer="offer")

          b-tab(title="Creatives")
            .widget
              .widget-header
                h2.title Creatives
              .widget-body
                .row
                  .col-sm-8
                    table.creative-list.table
                      thead
                        tr
                          th(style="width: 60px") &nbsp;
                          th Name
                          th Type
                          th(style="width: 60px") &nbsp;
                      tbody
                        tr(v-for="(c, i) in offer.creatives")
                          td.text-center
                            a.table-icon(:href="c.url", target="_blank")
                              img(v-if="c.type === 'image'", :src="c.url")
                              .video-placeholder(v-if="c.type === 'video'")
                                i.la.la-play
                          td
                            .name {{ c.name }}
                            small.form-text.text-muted.d-inline(v-if="c.type === 'image'") {{ c.w }}x{{ c.h }}
                            small.form-text.text-muted.d-inline(v-if="c.type === 'video'") {{ c.duration }} sec
                          td {{ c.type }}
                          td.actions
                            a.btn.btn-danger(@click="unselectCreative(i)", href="javascript:void(0)")
                              i.la.la-trash
                  .col-sm-4
                    .row
                      .col-sm-6.form-inline
                        .input-group.input-group-inline.input-group-rounded
                          input.form-control.input-search(type="text", placeholder="Search...", v-model="creativeSearch.filters.keyword", v-debounce:200ms="searchCreatives")
                      .col-sm-6.form-inline
                        .input-group.input-group-inline.input-group-rounded


                    .creative-search-results
                      .no-results.text-center(v-if="creativeSearch.results.length === 0") No creatives matching this search were found...
                      .creative-search-item(v-for="(c, i) in creativeSearch.results", @click="selectCreative(i)")
                        .image-container(v-bind:style="{'background-image': c.type === 'image' ? 'url(\"'+c.url+'\")' : ''}")
                          i.video-icon.la.la-play(v-if="c.type === 'video'")
                        .name {{ c.name }}


                    p.clearfix &nbsp;
                    button.btn.btn-secondary(type="button", @click="addCreative()") Upload New Creative

          //b-tab(title="Advanced")
            .widget
              .widget-header
                h2.title Developers
              .widget-body
                .row
                  .col-sm-4
                    .form-text
                      b-form-checkbox(v-model="offer.debug") Debug

          //b-tab(title="Statistics", v-if="offer.id")
            .widget
              .widget-body
                report-graph(type="line", :data="graphPerformance", title="General Performance")

          b-tab(title="Activity Log", v-if="offer.id")
            .widget
              .widget-body
                activity-log-table(type="offer", :id="offer.id", :publishers="publishers")


</template>

<style lang="scss">
.no-click {
  pointer-events: none;
}

.multi-inputs {

  &>.input-group-wrapper,
  &>.form-control {
    width: 48%;
    margin: 0 1%;
    display: inline-block;
  }
}

.custom-checkbox {
  &.cstatus-live {
    color: #43bc41;
  }

  &.cstatus-paused {
    color: #2c529b;
  }
}

table.table-macros {
  thead {
    tr {
      th {
        padding: 2px 2px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 2px 2px;
      }
    }
  }
}

table.event-list {

  //border: 1px solid #ccc;
  thead {
    tr {
      th {
        padding: 2px 2px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 2px 2px;
      }
    }
  }

  .ghost {
    border: 2px dashed #ccc;
  }
}

.page-offer-form {
  .app-icon-container {
    width: 120px;
    height: 120px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    background: #ccc;
    border-radius: 20px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 20px;
    }
  }
}

.translate-params-list {
  border-spacing: 0;

  thead {
    tr {
      th {
        padding: 3px;
      }
    }
  }

  tbody {
    border-bottom: 3px solid #aaa;

    tr {
      td {
        padding: 3px;
      }

      &.row-param {
        background: #f6f6f6;

        input.form-control {
          background: #fff;
          display: inline-block;
          max-width: 200px;
        }
      }
    }
  }

  input {
    height: 24px;
    line-height: 24px;
    padding: 0 10px;
  }
}

.creative-list.table {
  td {
    .table-icon {
      img {
        height: 28px;
        width: auto;
      }

      .video-placeholder {
        i {
          font-size: 30px;
        }
      }
    }

    &.actions {
      .btn {
        margin-right: 2px;
      }
    }
  }
}

.creative-search-results {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .no-results {
    height: 60px;
    font-size: 14px;
  }

  .creative-search-item {
    padding: 5px;

    &:hover {
      .image-container {
        border-radius: 5px;
      }

      .name {
        color: #ff945f;
      }
    }

    .image-container {
      transition: all 0.2s ease-in-out;
      width: 80px;
      height: 80px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      background: #f2f2f2;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
        border-radius: 15px;
      }

      i.video-icon {
        font-size: 50px;
      }
    }

    .name {
      transition: color 0.2s ease-in-out;
      margin-top: 5px;
      color: #29323a;
      width: 80px;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.wl-bl-tabs {
  .icon-blacklist {
    color: #dc3545;
  }

  .icon-whitelist {
    color: #28a745;
  }

  .icon-null {
    display: none;
  }
}

.drag-handle {
  cursor: grab !important;
}

.complex-offers-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .complex-offer-container {
    width: 100%;
    height: 2rem;
    padding-left: 0.5rem;
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    align-items: center;
    border-radius: 0.25rem;

    .main-switcher-container {
      position: absolute;
      right: 4rem;
      display: flex;
      height: 2rem;
      align-items: center;

      .la-link {
        color: var(--primary);
      }

      .la-unlink {
        color: #ccc;
      }

      .main-switcher {
        font-size: 20px;
      }
    }
  }

  .complex-offer-link {
    width: 2rem;
    height: 100%;
    background-color: #7a7d82;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
</style>

<script>
import Vue from 'vue';
import draggable from 'vuedraggable';
import CreativeModal from '../modals/CreativeModal';
import moment from 'moment';
import KpiRules from '../../components/form/KpiRules';
import { timezones } from '../../lib/datetime';

export default {
  name: 'OfferForm',
  watch: {
    // Call the method again if the route changes
    $route: 'initializeSearch',
  },
  async beforeRouteEnter(to, from, next) {
    let offerId = +to.params.id;
    let externalOfferId = +to.query.external_offer_id;
    let copyOfferId = +to.query.copy_offer_id;
    let advertiserId = +to.query.advertiser_id;
    let advertiser;
    let offer = {};
    let macros = [];
    try {
      let systemMacros = await Vue.ovData.general.getUrlMacros();
      macros = systemMacros.INTERNAL_TRACKING_MACROS;
    } catch (e) {
      console.error(e);
    }
    let advertiserOptions = await Vue.ovData.advertiser.getOptions(true);
    let userOptions = await Vue.ovData.user.getOptions(true, ['admin', 'am']);
    let mmpAccountOptions = await Vue.ovData.mmpAccount.getOptions(true);
    const generalKpiRules = await Vue.ovData.kpiRules.getAll();
    // Copy to prevent changing the original array
    let generalCustomKpiRules = JSON.parse(JSON.stringify(generalKpiRules));
    try {
      if (to.name === 'offer-edit') {
        offer = await Vue.ovData.offer.get(offerId, true);
        advertiser = await Vue.ovData.advertiser.get(offer.advertiser_id, true);
        // Change the active status of the general KPI rules based on the offer's rules.
        // Rules might be overridden by the offer's rules.
        generalCustomKpiRules = generalCustomKpiRules.map((r) => {
          const offerRule = offer.rules.find((or) => or.parent_rule_id === r.id);
          if (!offerRule) {
            return r;
          }

          return {
            ...r,
            active: offerRule.active
          };
        });
      } else {
        if (externalOfferId) {
          let externalOffer = await Vue.ovData.externalOffer.get(externalOfferId, true);
          if (!externalOffer) {
            return next(false);
          }
          offer = Vue.ovData.externalOffer.prepareOffer(externalOffer);
          advertiser = await Vue.ovData.advertiser.get(offer.advertiser_id, true);
        } else if (advertiserId) {
          advertiser = await Vue.ovData.advertiser.get(advertiserId, true);
          offer = Vue.ovData.offer.newInstance();
          offer.advertiser_id = advertiserId;
        } else if (copyOfferId) {
          offer = await Vue.ovData.offer.get(copyOfferId, true);
          offer.id = 0;
          advertiser = await Vue.ovData.advertiser.get(offer.advertiser_id, true);
        }
        if (!copyOfferId) {
          let offerDefaults = await Vue.ovData.offer.getDefaults(false);
          Object.assign(offer, offerDefaults);
        }
        offer.type = 'normal';
      }
    } catch (e) {
      Vue.ovNotify.error('Failed to load offer');
      return next(false);
    }
    if (!offer.restrict_timeframe_start) {
      offer.restrict_timeframe_start = '00:00';
    }
    if (!offer.restrict_timeframe_end) {
      offer.restrict_timeframe_end = '23:59';
    }
    if (advertiser.secret) {
      offer.advertiser_secret = advertiser.secret;
    }

    offer.main_parent_offer_id = offer.parentOffers
      ? offer.parentOffers.reduce((acc, cur) => {
        if (cur.is_main) {
          acc = cur.id;
        }
        return acc;
      }, undefined)
      : undefined;

    Vue.ovData.offer._booleans.forEach((f) => {
      offer[f] = !!offer[f];
    });

    let definitions = await Vue.ovReq.get('settings/getCampaignDefinitions');

    //{d:'s', hr:[]},{d:'s', hr:[]},{d:'s', hr:[]},{d:'s', hr:[]},{d:'s', hr:[]},{d:'s', hr:[]},{d:'s', hr:[]},];
    next((vm) => {
      if (offer.type === 'complex') {
        vm.$router.push({ name: 'complex-edit', params: { id: offer.id } });
      }
      if (offer.type === 'fallback') {
        vm.$router.push({ name: 'offer-fallback' });
      }
      if (!offer.id) {
        offer.user_id = vm.$store.state.user.id;
      }

      vm.restrictionList = definitions.restrictions;
      vm.advertiserOptions = advertiserOptions;
      vm.mmpAccountOptions = mmpAccountOptions;
      vm.userOptions = userOptions;
      if (to.name === 'offer-add') {
        offer = { ...offer, ...advertiser.offer_defaults };
      }
      vm.offer = offer;

      vm.urlMacros = macros;

      vm.advertiser = advertiser;
      vm.generalCustomKpiRules = generalCustomKpiRules;
      vm.generalKpiRules = generalKpiRules;
      vm.loadPlacementStatus();
      vm.searchCreatives();
      vm.dateRange = {
        // startDate: moment().format('DD/MM/YYYY'),
        // endDate: moment().format('DD/MM/YYYY'),
        startDate: new Date(),
        endDate: new Date(),
      };

      if (offer.daily_subsource_limit === -1) {
        vm.dailySubsourceLimitMode = 'advertiser';
      } else if (offer.daily_subsource_limit === 0) {
        vm.dailySubsourceLimitMode = 'nolimit';
      } else {
        vm.dailySubsourceLimitMode = 'custom';
      }

      if (offer.total_subsource_limit === -1) {
        vm.totalSubsourceLimitMode = 'advertiser';
      } else if (offer.total_subsource_limit === 0) {
        vm.totalSubsourceLimitMode = 'nolimit';
      } else {
        vm.totalSubsourceLimitMode = 'custom';
      }

      vm.offer.use_device_blacklisted = offer.deviceid_blacklist_id === 1;

      if (offerId) {
        vm.graphPerformance = {
          metrics: [
            'revenue',
            'cost',
            'profit',
            'profit_percent',
            'clicks',
            'installs',
            'conversions',
            'reported_conversions',
            'events',
            'reject_events',
          ],
          dimensions: [],
          group_timeframe: 'day',
          date_start: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
          date_end: moment().format('YYYY-MM-DD HH:mm'),
          sort: ['revenue', 'desc'],
          limit: 10,
          filters: {
            offer_id: [offerId],
          },
        };
      }

      vm.timeframeParams = {
        restrict_timeframe_schedule: offer.restrict_timeframe_schedule,
        restrict_timeframe_timezone: offer.restrict_timeframe_timezone,
        restrict_timeframe: offer.restrict_timeframe,
      };

      vm.geoParams = {
        geo_targeting_mode: offer.geo_targeting_mode,
        targeting_i2l: offer.targeting,
        targeting_de: offer.geo_targeting,
        lock_geo_targeting: offer.lock_geo_targeting,
      };

      vm.osNetworkParams = {
        carrier_whitelist: offer.carrier_whitelist,
        isp_whitelist: offer.isp_whitelist,
        should_block_wifi: offer.should_block_wifi || false,
        os_targeting: offer.os_targeting,
        os_targeting_version_min: offer.os_targeting_version_min,
        os_targeting_version_max: offer.os_targeting_version_max,
        os_version_blacklist: offer.os_version_blacklist,
        advanced_targeting_whitelist: offer.advanced_targeting_whitelist,
        advanced_targeting_blacklist: offer.advanced_targeting_blacklist,
      };
      console.log(vm.offer);
      return vm;
    });
  },
  components: {
    KpiRules,
    draggable,
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    allowFetchLink() {
      return (
        !this.offer.tracking_url &&
        this.offer.integration_type === 'has-offers' &&
        this.offer.external_offer_id
      );
    },
    shouldLockEvents() {
      return this.offer.main_parent_offer_id !== undefined;
    },
    mainParentOffer() {
      if (!this.offer.parentOffers) return {};
      return this.offer.parentOffers.find((o) => o.id === this.offer.main_parent_offer_id) || {};
    },
    publishers() {
      return this.placementStatusList.map((p) => ({ id: p.publisher_id, name: p.publisher_name }));
    },
    /** Whether the offer managed manually or by API */
    isAutomaticOffer() {
      return Boolean(this.offer.external_offer_id);
    },
    selectedMmpAccount() {
      return Vue.ovData.mmpAccount.getSync(this.offer.mmp_account_id);
    },
  },
  data() {
    return {
      timezones,
      timeframeParams: {},
      geoParams: {},
      osNetworkParams: {},
      dailySubsourceLimitMode: 'advertiser',
      totalSubsourceLimitMode: 'advertiser',
      busy: false,
      datepickerConf: {
        format: 'DD/MM/YYYY',
      },
      blwlFields: [
        { v: 'bundle', t: 'Bundle' },
        { v: 'appname', t: 'Appname' },
        { v: 'sub1', t: 'Pub.Subsource' },
        { v: 'adv_sub1', t: 'Adv.Subsource' },
        { v: 'referer', t: 'Referer' },
      ],
      restrictionList: [],
      placementStatusList: [],
      offer: null,
      generalCustomKpiRules: [],
      generalKpiRules: [],
      advertiser: null,
      advertiserOptions: [],
      mmpAccountOptions: [],
      userOptions: [],
      currencyOptions: Vue.ovData.general.currencyOptions,
      platformOptions: Vue.ovData.general.platformOptions,

      clickSigningResult: null,

      afParams: [
        { name: 'af_adset', platform: 'all', descr: 'AdSet Name' },
        { name: 'af_adset_id', platform: 'all', descr: 'AdSet ID' },
        { name: 'af_ad', platform: 'all', descr: 'Ad Name' },
        { name: 'af_ad_id', platform: 'all', descr: 'Ad ID' },
        { name: 'af_keywords', platform: 'all', descr: 'Keyword' },
        {
          name: 'af_channel',
          platform: 'all',
          descr: 'Media Source Channel (UAC_Search, Instagram...)',
        },
        { name: 'af_ref', platform: 'all', descr: 'Referrer' },
        {
          name: 'af_ad_type',
          platform: 'all',
          descr: 'Ad Type',
          defaultValue: 'text\nbanner\ninterstitial\nplayable',
        },
      ],
      encodeFreqOptions: [
        { v: 6, t: '6 hours' },
        { v: 12, t: '12 hours' },
        { v: 24, t: '24 hours' },
        { v: 2 * 24, t: '2 days' },
        { v: 3 * 24, t: '3 days' },
        { v: 4 * 24, t: '4 days' },
        { v: 5 * 24, t: '5 days' },
        { v: 6 * 24, t: '6 days' },
        { v: 7 * 24, t: '7 days' },
      ],
      refererModeOptions: [
        { v: 'none', t: 'None' },
        { v: 'block_empty', t: 'Block Empty referer' },
        { v: 'block_nonempty', t: 'Block Clicks with referer' },
      ],
      subsourceModeOptions: [
        { v: 'none', t: 'None' },
        { v: 'block_empty', t: 'Block Empty' },
        { v: 'override', t: 'Override' },
        { v: 'override_empty', t: 'Override Empty' },
        { v: 'encode', t: 'Encode' },
      ],
      customModeOptions: [
        { v: 'none', t: 'None' },
        { v: 'block_empty', t: 'Block Empty' },
        { v: 'override', t: 'Override' },
        { v: 'override_empty', t: 'Override Empty' },
      ],
      clickParamModeOptions: [
        { v: 'none', t: 'None' },
        { v: 'override', t: 'Override' },
        { v: 'override_empty', t: 'Override Empty' },
      ],
      appnameModeOptions: [
        { v: 'none', t: 'None' },
        { v: 'block_empty', t: 'Block Empty' },
        { v: 'override', t: 'Override' },
        { v: 'override_empty', t: 'Override Empty' },
        { v: 'override_invalid', t: 'Override Invalid' },
      ],
      deviceidModeOptions: [
        { v: 'none', t: 'None' },
        { v: 'block_empty', t: 'Block Empty' },
        // { v: 'generate', t: 'Generate' },
        // { v: 'generate_empty', t: 'Generate Empty' },
      ],
      distributionModeOptions: [
        { v: 'evenly', t: 'Evenly' },
        //{ v: 'priority', t: 'Priority' },
        { v: 'alternate_weight', t: 'Alternate Weight' },
        { v: 'sync_random', t: 'Synced' },
      ],
      scanFrequencyOptions: [
        { v: 0, t: 'No scan' },
        { v: -1, t: 'Once' },
        { v: 60, t: 'Hour' },
        { v: 120, t: '2 Hours' },
        { v: 360, t: '6 Hours' },
        { v: 720, t: '12 Hours' },
        { v: 1440, t: '24 Hours' },
        { v: 2880, t: '48 Hours' },
        { v: 4320, t: '72 Hours' },
      ],
      statusOptions: [
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // { v: 'ended', t: 'Ended' }
      ],
      redirectTypeOptions: [
        { v: null, t: 'N / A' },
        { v: '200', t: 'HTTP 200 (After page load)' },
        { v: '302', t: 'HTTP 302 (Default)' },
      ],
      payoutTypeOptions: Vue.ovData.general.payoutTypeOptions,
      urlMacros: Vue.ovData.general.urlMacros,

      creativeSearch: {
        filters: {
          keyword: null,
          app_id: null,
          type: null,
        },
        pagination: {
          total: 0,
          page: 1,
          page_size: 20,
        },
        results: [],
      },
      graphPerformance: null,
      validDateRange: true,
      dateWarning: '',
      editAutoGenerate: false,
    };
  },
  methods: {
    updateTimeframe(params) {
      this.offer.restrict_timeframe = params.restrict_timeframe;
      this.offer.restrict_timeframe_timezone = params.restrict_timeframe_timezone;
      this.offer.restrict_timeframe_schedule = params.restrict_timeframe_schedule;
    },
    updateGeo(params) {
      this.offer.targeting = params.targeting_i2l;
      this.offer.geo_targeting = params.targeting_de;
      this.offer.geo_targeting_mode = params.geo_targeting_mode;
      this.offer.lock_geo_targeting = params.lock_geo_targeting;
    },
    onDateChange() {
      this.validDateRange =
        !this.offer.date_start ||
        !this.offer.date_end ||
        moment(this.offer.date_start, 'YYYY-MM-DD').isBefore(
          moment(this.offer.date_end, 'YYYY-MM-DD'),
        );
    },

    // updateDailySubsourceLimit() {
    // 	console.log(this.dailySubsourceLimitMode);
    // 	console.log(this.offer.daily_subsource_limit);
    // },

    addRouteParam() {
      this.offer.route_params.push({
        param: '',
        values: [
          {
            k: '',
            v: '',
          },
        ],
      });
    },

    addRouteParamItem(p) {
      p.values.push({
        k: '',
        v: '',
      });
    },

    addEvent() {
      this.offer.events.push({
        name: '',
        label: '',
        payout_mode: 'none',
        payout: 0,
      });
    },

    fetchImpressionLink() { },

    async fetchLink() {
      let data = {
        id: this.offer.external_offer_id,
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.post('externalOffer/generateTrackingLink', data);
        this.offer.tracking_url = resp.tracking_url;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async fetchStoreData() {
      const data = {
        url: this.offer.preview_url,
        country: this.offer.country ? this.offer.country.substring(0, 2) : '',
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('offer/getStoreData', { params: data });
        this.offer.store_bundle_id = resp.bundle_id;
        this.offer.store_icon = resp.icon;
        this.offer.store_package_id = resp.package_id;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    // TODO: remove after OC-680 is done
    async loadPlacementStatus() {
      // if (!this.offer.id) {
      // 	return;
      // }
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('placement/getOfferStatus/' + this.offer.id);
        this.placementStatusList = resp.placement_status_list;
        this.placementStatusList.forEach((p) => {
          p.create_placement = p.id !== null && p.status === 'live';
          p.disabled = !!p.auto_generated;
        });
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async submit() {
      if (this.busy) {
        return;
      }
      if (this.offer.reserved) {
        return this.$ovNotify.error('You are not authorized to perform this action');
      }
      let data = Vue.util.extend({}, this.offer);

      Vue.ovData.offer._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });

      if (this.USER.config && this.USER.config.deviceBL) {
        // device blacklist - only for Glispa
        data.use_device_blacklisted = data.use_device_blacklisted ? 1 : 0;
      }

      let restrictionList = [];
      for (let k in data.restriction_hash) {
        if (!data.restriction_hash[k]) continue;
        restrictionList.push(k);
      }
      data.restriction_list = restrictionList;

      delete data.black_list;
      delete data.translated_black_list;

      switch (this.dailySubsourceLimitMode) {
        case 'advertiser':
          data.daily_subsource_limit = -1;
          break;
        case 'nolimit':
          data.daily_subsource_limit = 0;
          break;
      }

      switch (this.totalSubsourceLimitMode) {
        case 'advertiser':
          data.total_subsource_limit = -1;
          break;
        case 'nolimit':
          data.total_subsource_limit = 0;
          break;
      }

      data.os_targeting = this.osNetworkParams.os_targeting;
      data.os_targeting_version_min = this.osNetworkParams.os_targeting_version_min;
      data.os_targeting_version_max = this.osNetworkParams.os_targeting_version_max;
      data.os_version_blacklist = this.osNetworkParams.os_version_blacklist;
      data.carrier_whitelist = this.osNetworkParams.carrier_whitelist;
      data.isp_whitelist = this.osNetworkParams.isp_whitelist;
      data.should_block_wifi = this.osNetworkParams.should_block_wifi;
      data.advanced_targeting_whitelist = this.osNetworkParams.advanced_targeting_whitelist;
      data.advanced_targeting_blacklist = this.osNetworkParams.advanced_targeting_blacklist;

      if (
        data.os_targeting_version_min &&
        Number.isNaN(this.versionToNumber(data.os_targeting_version_min))
      ) {
        return this.$ovNotify.error('OS minimum version is not a valid version number');
      }

      if (
        data.os_targeting_version_max &&
        Number.isNaN(this.versionToNumber(data.os_targeting_version_max))
      ) {
        return this.$ovNotify.error('OS maximum version is not a valid version number');
      }

      if (data.os_targeting_version_min && data.os_targeting_version_max) {
        const minNumber = this.versionToNumber(data.os_targeting_version_min);
        const maxNumber = this.versionToNumber(data.os_targeting_version_max);
        if (minNumber > maxNumber) {
          return this.$ovNotify.error('OS version min must be less than OS version max');
        }
      }

      try {
        this.busy = true;
        await this.$ovReq.post('offer/save', data);
        this.busy = false;
        this.$ovNotify.success('Offer has been saved');
        this.$router.push({ name: 'offer-list' });
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },

    async searchCreatives() {
      this.creativeSearch.busy = true;
      try {
        let params = {
          keyword: this.creativeSearch.filters.keyword,
          page: this.creativeSearch.pagination.page,
          page_size: this.creativeSearch.pagination.page_size,
        };
        let resp = await Vue.ovData.creative.getList(params);
        this.creativeSearch.results = resp.records.filter(
          (r) => !this.offer.creatives.find((c) => c.id === r.id),
        );
        this.creativeSearch.pagination.total = resp.total;
      } catch (e) {
        console.error(e);
      }
      this.creativeSearch.busy = false;
    },

    selectCreative(index) {
      let selected = this.creativeSearch.results.splice(index, 1);
      if (selected.length > 0) {
        this.offer.creatives.push(selected[0]);
      }
    },

    unselectCreative(index) {
      this.offer.creatives.splice(index, 1);
      this.searchCreatives();
    },

    addCreative() {
      let entities = this.offer.creatives;
      this.$modal.show(
        CreativeModal,
        {
          entities,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': (/*event*/) => { },
        },
      );
    },

    randomString: function (n) {
      let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let s = '';
      for (let i = 0; i < n; i++) {
        s += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return s;
    },

    updateOfferMMP(mmpData) {
      this.offer.mmp = mmpData.mmp;
      let matchingAccount = this.mmpAccountOptions.find(
        (o) => o.account_name === mmpData.mmpAccountName,
      );
      if (matchingAccount) {
        Vue.set(this.offer, 'mmp_account_id', matchingAccount.v);
      }
    },
    /**
     * Update custom params of the impression or click URL
     * @param {object} o
     * @param {string} o.customParams
     * @param {'impression' | 'click'} o.type
     */
    updateOfferCustomParams({ customParams, type }) {
      if (type === 'impression') {
        this.offer.impression_custom_params = customParams;
      } else {
        this.offer.custom_params = customParams;
      }
    },

    /**
     * Convert a version string into a number.
     * @param {string} version - The version string to convert in the format "major.minor.patch.build".
     * @returns {number} - The version number.
     */
    versionToNumber(version) {
      const numbers = version.split('.').map(Number);

      while (numbers.length < 4) {
        for (let i = 0; i < 4 - numbers.length; i++) {
          numbers.push(0);
        }
      }

      const [major, minor, patch, build] = numbers;
      return major * 1000000 + minor * 10000 + patch * 100 + build;
    },

    updateMainParentOffer(parentId) {
      let matchingOffer = this.offer.parentOffers.find((o) => o.id === parentId);
      if (!matchingOffer) return;
      if (this.offer.main_parent_offer_id === matchingOffer.id) {
        this.offer.main_parent_offer_id = undefined;
        return;
      }

      this.offer.main_parent_offer_id = matchingOffer.id;
    },

    async testAfClickSigning() {
      const id = this.offer.id;
      const url = `offer/testAfClickSignature/${id}`;
      const response = await this.$ovReq.get(url);
      if (response.isValid) {
        this.clickSigningResult = response.message;
      } else {
        this.clickSigningResult = response.message;
        this.$ovNotify.error(response.message);
      }
    }
  },
};
</script>
